import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OrientationPurpose} from 'src/app/services/calculator/models/calculator-clinical-characteristics-model';
import {Score} from 'src/app/services/score/model/score.model';
import {ScoreService} from 'src/app/services/score/score.service';
import {ObesityTreatment} from '../../../../services/calculator/models/calculator-product-result-model';
import {HeaderTable} from '../generic-table/model/generic-table.model';
import {MonthlyProgressDto} from './model/monthly-progress.dto';
import {SpecieEnum} from '../../enums/specie.enum';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrl: './score.component.scss',
})
export class ScoreComponent implements OnChanges, OnDestroy {
  @Input() specieSelected: SpecieEnum;
  @Input() orientationPurpose: OrientationPurpose;
  @Input() selectedActivityId: number;
  @Input() obesityTreatment: ObesityTreatment;
  @Input() weight: any;

  @Output() scoreSelectedEmitter = new EventEmitter<Score>();

  showContainer = false;
  visibleDescription = false;
  scoreSelected: Score;
  scores: Score[];
  scoreListMobile: number[];
  titleButtonToggle = 'Ver tratamento completo';
  openTable = false;
  headerTable: HeaderTable[] = [];
  mobileScreen: boolean;
  screenWidth: number;
  contentTable: MonthlyProgressDto[] = [];
  iconObesityTreatmentCat = './../../../../../assets/images/weight-scale.svg';
  showLoading = false;

  private destroy$ = new Subject<void>();

  constructor(
    private scoreService: ScoreService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number): void {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 640
      ? (this.mobileScreen = false)
      : (this.mobileScreen = true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const positionTable = this.openTable;
    this.openTable = false;
    if (changes.orientationPurpose || changes.selectedActivityId) {
      this.getScores();
    }
    if (changes) {
      this.contentTable = [];
      this.loadScore();
    }
    this.changeDetectorRef.detectChanges();
    this.openTable = positionTable;
  }

  showDescription(score: Score): void {
    this.visibleDescription = true;
    this.showLoading = true;
    this.scoreSelected = score;
    this.scoreSelectedEmitter.emit(this.scoreSelected);
  }

  getScores(): void {
    this.scoreService
      .getScores(this.specieSelected, this.orientationPurpose.id, this.selectedActivityId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.scores = response;
        this.showContainer = true;
        this.scoreSelected = this.scores[0];
        this.scoreListMobile = response.map((score) => score.order);
        this.scoreSelectedEmitter.emit(this.scoreSelected);
      });
  }

  capitalizeString(str: string): string {
    return str
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
  }

  getScoreMobileSelected(numberScore: number): void {
    this.showLoading = true;
    this.scoreSelected = this.scores.find((s) => s.order === numberScore);
    this.scoreSelectedEmitter.next(this.scoreSelected);
    this.changeDetectorRef.detectChanges();
  }

  @ViewChild('realWeightLoss', {static: false})
  public realWeightLossTemplate: TemplateRef<any>;

  HeaderTableScore(): HeaderTable[] {
    const headers = [
      {
        prop: 'dateFormatted',
        name: 'Período',
        visible: true,
      },
      {
        prop: 'monthlyWeightLoss',
        name: 'Perda Esperada',
        visible: true,
      },
      {
        prop: 'weightFormatted',
        name: 'Peso Mensal',
        visible: true,
      },
      {
        prop: 'gramsPerDay',
        name: 'Qtd Alimento Diária',
        visible: this.mobileScreen ? false : true,
      },
    ];
    if (this.obesityTreatment?.isReturn){
      const realLossColumn = new HeaderTable();
      realLossColumn.prop = 'realWeightLoss';
      realLossColumn.name = 'Perda Real';
      realLossColumn.visible =  this.weight !== undefined && this.weight !== '';
      realLossColumn.template = this.realWeightLossTemplate;
      headers.splice(2, 0, realLossColumn);
    }
    return headers;
  }

  loadScore(): void {
    this.contentTable = [];
    this.headerTable = this.HeaderTableScore();
    this.headerTable = [...this.headerTable];
    if (this.obesityTreatment) {
      this.obesityTreatment.monthlyProgress.map((element) => {
        this.contentTable.push(
          new MonthlyProgressDto(
            element.dateFormatted,
            element.weight,
            element.monthlyWeightLoss,
            element.gramsPerDay,
            element.nextReturn,
            element.currentOrientation,
            element.firstObesityTreatment,
            element.realWeightLoss
          )
        );
        this.contentTable = [...this.contentTable];
      });
    }

    this.showLoading = false;
    return;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleTable(): void {
    this.openTable = !this.openTable;
    this.changeDetectorRef.detectChanges();
    this.loadScore();
  }

  getWeightDifference(weight: string): string {
    if (weight === 'Inicio' || weight === '-') {
      return 'startTreatment';
    }

    return weight?.includes('-') ? 'realWeightNegative' : 'realWeightPositive';
  }

  get IsReturnObesityTreatmentWithCurrentWeight(): boolean {
    if (this.obesityTreatment) {
      if (
        this.obesityTreatment.previousTreatmentDurationInMonths ||
        this.obesityTreatment.previousWeightToLose ||
        this.obesityTreatment.previousDailyKcal
      ) {
        return true;
      }
    }

    return false;
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const pixelMeta = environment.pixelMeta;

if (pixelMeta) {
  // Adiciona o script do Facebook Pixel ao documento
  (function(f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js', null, null, null);

  // Aguarda o script ser carregado antes de usar o fbq
  (window as any).fbq = (window as any).fbq || function() {
    (window as any).fbq.callMethod ? (window as any).fbq.callMethod.apply((window as any).fbq, arguments) : (window as any).fbq.queue.push(arguments);
  };

  (window as any).fbq('init', pixelMeta);
  (window as any).fbq('track', 'PageView');
} else {
  console.warn('Facebook Pixel não configurado. Propriedade pixelMeta está vazia.');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
